import "./Navbar.css"
import BuyMeACoffeeButton from "../Donation/BuyMeACoffeeButton"
import { NavHashLink as NavLink } from 'react-router-hash-link';
import logo from './logo192.png'

import {
    FaHome
} from 'react-icons/fa';

function Navbar() {
    return (
        <nav className="main-navbar">
            {(
                <NavLink
                    to='/'>
                    <FaHome
                        className='landing--social'
                        style={{ color: "black" }}
                        aria-label='Github'
                    />
                </NavLink>
            )}
            <ul className="navbar-nav">
                {/* <li className="navbar-item">
                    <NavLink
                        to='/Catering'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                        Catering
                    </NavLink>
                </li> */}
                {/* <li className="navbar-item">
                    <NavLink
                        to='/Upcoming Events'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                        Upcoming Events
                    </NavLink>
                </li> */}
                <li className="navbar-item">
                    <NavLink
                        to='/contribute'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                        Contribute
                    </NavLink>
                </li>
                <li className="navbar-item">
                    <NavLink
                        to='#contact'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                        Contact
                    </NavLink>
                </li>
                <li className="navbar-item">
                    <NavLink
                        to='/Menu'
                        smooth={true}
                        spy='true'
                        duration={2000}
                    >
                        Menu
                    </NavLink>
                </li>
                <li className="navbar-item">
                <a data-formkit-toggle="8f08534f9e" href="https://jackydam.ck.page/8f08534f9e">Subscribe</a>
                </li>
                <BuyMeACoffeeButton />
            </ul>

        </nav>
    );
}

export default Navbar