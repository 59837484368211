import "./Contribute.css"

import React from 'react'
import { Carousel, Globalalert, Navbar, Footer, Contact, CatalogGrid } from '../../components'
import { Helmet } from 'react-helmet'

function Contribute() {
    const carouselItems = [
        {
            imageURL: "https://example.com/banner1.jpg",
            header: "Contribute Directly to my Small Business",
            body: "All contributions will go directly into making my pop up better and opening a restaurant.",
            ctaText: "Donate Now",
            ctaURL: "youtube.com",
            secondaryctaText: "View Goal",
            secondaryctaURL: ""
        },
        {
            imageURL: "https://example.com/banner2.jpg",
            header: "There are other ways to support my Small Business..",
            body: "Check out my affiliate links. Affiliate Links make it possible to earn a small commission so if you find something you're already interested in, we both become weiners.. I will not provide an affiliate link if I have never tried it or never use the service.",
            ctaText: "Affiliate Links",
            ctaURL: "#affiliate",
            secondaryctaText: "",
            secondaryctaURL: ""
        },
        {
            imageURL: "https://example.com/banner3.jpg",
            header: "Visit my Solana Beach Farmer Market Pop Up Location",
            body: "Thank you for visiting my website!! I hope that you can join me at my pop up events happening every Sunday from 8am - 4pm.",
            ctaText: "Set a Reminder",
            ctaURL: "#filler",
            secondaryctaText: "Menu",
            secondaryctaURL: ""
        }
    ];

    const productItems = [
        {
            imageURL: "https://assets.wsimgs.com/wsimgs/ab/images/dp/wcm/202421/0262/img1m.jpg",
            header: "Checkered Cutting Board",
            body: "$155.55",
            ctaText: "Buy Now",
            ctaURL: "",
        },
        {
            imageURL: "https://assets.wsimgs.com/wsimgs/ab/images/dp/wcm/202421/0262/img1m.jpg",
            header: "Checkered Cutting Board",
            body: "$155.55",
            ctaText: "Buy Now",
            ctaURL: "",
        },
        {
            imageURL: "https://assets.wsimgs.com/wsimgs/ab/images/dp/wcm/202421/0262/img1m.jpg",
            header: "Checkered Cutting Board",
            body: "$155.55",
            ctaText: "Buy Now",
            ctaURL: "",
        },
        {
            imageURL: "https://assets.wsimgs.com/wsimgs/ab/images/dp/wcm/202421/0262/img1m.jpg",
            header: "Checkered Cutting Board",
            body: "$155.55",
            ctaText: "Buy Now",
            ctaURL: "",
        },
        {
            imageURL: "https://assets.wsimgs.com/wsimgs/ab/images/dp/wcm/202421/0262/img1m.jpg",
            header: "Checkered Cutting Board",
            body: "$155.55",
            ctaText: "Buy Now",
            ctaURL: "",
        },
        {
            imageURL: "https://assets.wsimgs.com/wsimgs/ab/images/dp/wcm/202421/0262/img1m.jpg",
            header: "Checkered Cutting Board",
            body: "$155.55",
            ctaText: "Buy Now",
            ctaURL: "",
        },
    ];

    const globalAlertItems = {
        body: "My next pop up event will take place at the Solana Beach Farmers Market on [Insert DateValue] from 8am-12pm",
        shortBody: "Solana Beach Farmers Market 12PM - 4PM",
        ctaText: "View on Google Maps",
        ctaURL: "https://www.google.com/maps/dir/32.8036751,-117.1187964/google+maps+solana+beach+farmers+market/@32.8746917,-117.3569352,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x80dc0ed360f600ed:0xad456ac15c209a71!2m2!1d-117.269058!2d32.9870374?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D"
    }

    return (
        <div id="contribute" className="site">
            <Helmet>
                <title>Jacky Dam</title>
            </Helmet>
            
            <Globalalert globalAlertItems={ globalAlertItems }/>

            <div className="hero-banner">
                <Navbar />
                <Carousel carouselItems={ carouselItems }/>
            </div>

            <div id="affiliate" className="affiliate-section">
                <CatalogGrid catalogItems={ productItems }/>
            </div>

            
            <Contact />

            <Footer />

        </div >
    )
}

export default Contribute