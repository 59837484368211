import "./Home.css"

import React from 'react'
import { Carousel, Globalalert, Navbar, Footer, Contact, GenericHeroBanner } from '../../components'
import { Helmet } from 'react-helmet'

import image1 from "./banhmi-img1.avif"
import image2 from "./banhmi-img2.jpg"
import image3 from "./IMG_5556.jpg"

function Home() {
    const carouselItems = [
        {
            imageURL: "https://example.com/banner1.jpg",
            header: "Banh Mi Pop Up Meets San Diego!",
            body: "Come stop by my pop up event at the Solana Beach Farmers Market starting on [Insert DateValue] happening every Sunday between 8am - 12pm.",
            ctaText: "Set Google Reminder",
            ctaURL: "https://calendar.app.google/NJxet5p7f4W7L8KA9",
            secondaryctaText: "Learn More",
            secondaryctaURL: ""
        }
    ];

    const globalAlertItems = {
        body: "My next pop up event will take place at the Solana Beach Farmers Market on [Insert DateValue] from 8am-12pm",
        shortBody: "Solana Beach Farmers Market 12-4PM",
        ctaText: "View on Google Maps",
        ctaURL: "https://www.google.com/maps/dir/32.8036751,-117.1187964/google+maps+solana+beach+farmers+market/@32.8746917,-117.3569352,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x80dc0ed360f600ed:0xad456ac15c209a71!2m2!1d-117.269058!2d32.9870374?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D"
    }

    return (
        <div id="homepage" className="site">
            <Helmet>
                <title>Jacky Dam</title>
            </Helmet>
            
            <Globalalert globalAlertItems={ globalAlertItems }/>

            <GenericHeroBanner carouselItems={ carouselItems }/>

            <div className="service-bar">
                <div className="service-container">
                    <ol>
                        <li>🥡 Good Food</li>
                        <li>🍃 Locally Sourced</li>
                        <li>❤️ Made with Care</li>
                        <li>🎢 Homemade Recipes</li>
                        <li>🚀 Made Perfect</li>
                        <li>🍞 Fresh Bread</li>

                    </ol>
                </div>
            </div>

            <div className="product-description">
                <figure className="image-container">
                    <img src={image1} alt="Banh Mi Image" height={500}></img>
                    <figcaption>Source: The Illustration Room</figcaption>
                    <p>The best Banh Mi brings together a medley of ingredients which creates a symphony of many flavor profiles — you get the savoriness from the BBQ meat, tanginess from the pickled vegetables, and a balance of freshness and spice wrapped perfectly in a Vietnamese baguette.</p>
                </figure>
    
            </div>

            <div className="quote">
                <div className="quote-image">
                    <img src={image3} alt="MMA Fighter" height={500}></img>
                </div>
                <p>I fear not the man who can make 10,000 different banh mi recipes, but I fear the man who has made one banh mi 10,000 times.</p>
                <p>- Jacky Dam</p>
            </div>
            
            <Contact />

            <Footer />

        </div >
    )
}

export default Home