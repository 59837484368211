import "./CatalogGrid.css"

function CatalogGrid({ catalogItems }) {
    return (
        <div className="catalog-grid">
            <ol className="catalog-container">
                {catalogItems.map((catalogitem, index) => (
                <li id={`catalogitem-${index}`} className="catalogitem" tabindex="0">
                    <img src={catalogitem.imageURL} className="catalogitem-image" />
                    <h2>{catalogitem.header}</h2>
                    <p>{catalogitem.body}</p>
                    <a className="catalogitem-button" href={catalogitem.ctaURL}>{catalogitem.ctaText}</a>
                </li>
                ))}
            </ol>
        </div>

    );
}

export default CatalogGrid;