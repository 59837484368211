import Navbar from "../Navbar/Navbar";
import "./GenericHeroBanner.css"

function GenericHeroBanner( { carouselItems }) {

    return (
        <div className="hero-banner">
            <Navbar />
            <section className="web-carousel">
                <ol className="carousel-container">
                    {carouselItems.map((carousel, index) => (
                        <li id={`carousel-item-${index}`} className="carousel-slide" tabindex="0">
                            <h2>{carousel.header}</h2>
                            <p>{carousel.body}</p>
                            
                            <a className="cta-button" href={carousel.ctaURL} target="_blank" rel="noopener noreferrer">{carousel.ctaText}</a>
                            <a className="secondary-cta-button" href={carousel.secondaryctaURL} target="_blank" rel="noopener noreferrer">{carousel.secondaryctaText}</a>
                            
                        </li>
                    ))}

                </ol>
                <div className="carousel-image-container">
                    <img src="https://www.sandiegouniontribune.com/wp-content/uploads/migration/2023/11/19/0000018a-d451-d244-addb-f77792520000.jpg?w=535" className="carousel-image" />
                </div>

            </section>
        </div>
    );
}

export default GenericHeroBanner