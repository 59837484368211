import { useRef, useEffect } from "react";
import "./Carousel.css"
import { FaCalendar } from "react-icons/fa";

function Carousel({ carouselItems }) {
    
    return (
        <section className="web-carousel">
            <ol className="carousel-container">
                {carouselItems.map((carousel, index) => (
                    <li id={`carousel-item-${index}`} className="carousel-slide" tabindex="0">
                        {/* <img  className="carousel-image" /> */}
                        <h2>{carousel.header}</h2>
                        <p>{carousel.body}</p>
                        
                        <a className="cta-button" href={carousel.ctaURL} target="_blank" rel="noopener noreferrer">{carousel.ctaText}</a>
                        <a className="secondary-cta-button" href={carousel.secondaryctaURL} target="_blank" rel="noopener noreferrer">{carousel.secondaryctaText}</a>
                    </li>
                ))}
            </ol>

            {/* <button className="carousel-arrow prev-arrow">&#10094;</button>
            <button className="carousel-arrow next-arrow">&#10095;</button> */}
        </section>
    
    );
}

export default Carousel